import React from "react";

import AddVariaion from "./Addvariation";
const AddVariants = () => {
  return (
    <>
      <AddVariaion />
    </>
  );
};

export default AddVariants;
