import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from 'react-router-dom';
import { updatevendor, shippingprice, warehouseget, warehousegetbyid, confirm_vendor } from "../../services/api";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import useEnhancedEffect from "@mui/material/utils/useEnhancedEffect";

export const StatusPosts = ({ porData }) => {
  let srno = 0;
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [selectedProductId, setSelectedProductId] = useState(null);
  const [selectedPorId, setSelectedPorId] = useState(null);
  const [selectedVendorId, setSelectedVendorId] = useState(null);
  const [selectedProductName, setSelectedProductName] = useState("");
  const [selectedQuantity, setSelectedQuantity] = useState("");
  const [selectedproductprice, setSelectedproductprice] = useState("");
  const [selectedproductadmshipprice, setSelectedproductadmshipprice] = useState("");
  const [vendorprice, setVendorprice] = useState("");
  const [vendorshipprice, setVendorshipprice] = useState(0);
  const [vendortotal, setVendortotal] = useState(0);
  const [dataofship, setDataofship] = useState([]);
  const [warehouse, setwarehouse] = useState([]);
  const [warehouseid, setwarehouseid] = useState("");
  const [isDataReady, setIsDataReady] = useState(false);

  const [modaldata, setModaldata] = useState({
    productid: "",
    porid: "",
    variantid: "",
    quantity: "",
    pincode: "",
    productname: "",
    productprice: "",
    admintotalprice: "",
    adminshippingprice: "",
    vendorprice: "",
    vendorshippingprice: "",
    vendortotalprice: "",
    selectedware: "",
    selectedvendor: "",
    vendors: [],
    vendorsdetail: []

  })

  // const handlewarehousechange = async (e) => {
  //   console.log("getting data");
  //   const selectedwaresouseid = e.target.value
  //   setwarehouseid(selectedwaresouseid);
  //   const warehouseIndex = dataofship.vendorWareHouse.findIndex(
  //     (vendor) => vendor.warehouseid === warehouseid
  //   );
  //   console.log(warehouseIndex);

  //   if (warehouseIndex !== -1) {
  //     let shprice = parseFloat(dataofship.shipCost[warehouseIndex]);
  //     let tprice = parseFloat(dataofship.pricesresult[warehouseIndex]);
  //     setVendorshipprice(shprice);
  //     setVendorprice(tprice);
  //     console.log(vendorprice, vendorshipprice);
  //     setVendortotal(shprice + tprice);
  //     // Assuming total is price + shipping cost
  //   } else {
  //     // Handle case where vendor is not found
  //     setVendorshipprice('');
  //     setVendorprice('');
  //     setVendortotal('');
  //   }
  // }

  const handlewarehousechange = async (e) => {
    console.log("getting data");
    const selectedwaresouseid = e.target.value
    console.log(selectedwaresouseid);
    setwarehouseid(selectedwaresouseid);
    const warehouseIndex = modaldata.vendors.vendorWareHouse.findIndex(
      (vendor) => vendor.warehouseid == selectedwaresouseid
    );
    console.log("Warehouse Index:", warehouseIndex);

    setModaldata({
      ...modaldata,
      vendorshippingprice: parseFloat(modaldata.vendors.shipCost[warehouseIndex]),
      vendorprice: parseFloat(modaldata.vendors.pricesresult[warehouseIndex] * modaldata.quantity),
      vendortotalprice: parseFloat(modaldata.vendors.pricesresult[warehouseIndex] * modaldata.quantity) + parseFloat(modaldata.vendors.shipCost[warehouseIndex]),
      selectedware: selectedwaresouseid
    });

  }

  const handleVendorSelect = async (selectedVendorId, warehouseid, selectedPorId, selectedProductId) => {
    try {
      console.log("updating selected vendor")
      let data = await updatevendor(selectedPorId, selectedProductId, selectedVendorId, warehouseid);
      console.log(data);
      alert(data.message);
      // window.location.reload();
    } catch (error) {
      console.error("Error updating selected vendor:", error);
    }
  };

  // 

  const handleVendorChange = async (e) => {
    const selectedVendorId = e.target.value;
    setSelectedVendorId(selectedVendorId);
    console.log(selectedVendorId)

    // Filter warehouses associated with the selected vendor
    const vendorWarehouses = modaldata.vendors.vendorWareHouse
      .filter(vendor => vendor.vendorid == selectedVendorId)
      .map(vendor => vendor.warehouseid);

    try {
      // Make API calls for each warehouse
      console.log(vendorWarehouses)
      const warehouseDetailsPromises = vendorWarehouses.map(warehouse => warehousegetbyid(warehouse));
      const warehouseDetails = await Promise.all(warehouseDetailsPromises);

      console.log(warehouseDetails);
      setwarehouse(warehouseDetails);
      setwarehouseid(warehouseDetails[0]?.value);
      const warehouseIndex = modaldata.vendors.vendorWareHouse.findIndex(
        (vendor) => vendor.warehouseid == warehouseDetails[0]?._id
      );
      console.log(warehouseDetails[0]?._id);
      setModaldata({
        ...modaldata,
        vendorshippingprice: parseFloat(modaldata.vendors.shipCost[warehouseIndex]),
        vendorprice: parseFloat(modaldata.vendors.pricesresult[warehouseIndex] * modaldata.quantity),
        vendortotalprice: parseFloat(modaldata.vendors.pricesresult[warehouseIndex] * modaldata.quantity) + parseFloat(modaldata.vendors.shipCost[warehouseIndex]),
        selectedware: warehouseDetails[0]?._id,
        selectedvendor: selectedVendorId
      });
    } catch (error) {
      console.error("Error fetching warehouse details:", error);
      // Handle error, e.g., show a message to the user
    }
  };



  // const openmodel = () => {
  //   console.log(modaldata);
  //   if (modaldata.productid && modaldata.porid && modaldata.variantid && modaldata.quantity && modaldata.pincode && modaldata.productname && modaldata.productprice && modaldata.admintotalprice && modaldata.adminshippingprice && modaldata.vendorprice && modaldata.vendorshippingprice && modaldata.vendortotalprice && modaldata.vendors) {
  //     setShowModal(true);
  //   }
  // };

  const handleOpenModal = async (porId, productId, productname, productprice1, productqunatity, admshipprice, productvarient, productpin, vendorsdetail, selectedware, selectedvendor) => {

    console.log(vendorsdetail);
    function addArrays(arr1, arr2) {
      if (arr1.length !== arr2.length) {
        throw new Error("Arrays must have the same length");
      }
      let result = [];
      for (let i = 0; i < arr1.length; i++) {
        const num1 = typeof arr1[i] === 'string' ? parseFloat(arr1[i]) : arr1[i];
        const num2 = typeof arr2[i] === 'string' ? parseFloat(arr2[i]) : arr2[i];
        result.push(num1 + num2);
      }
      return result;
    }

    function findIndexOfMinValue(array) {
      let minValue = array[0];
      let minIndex = 0;
      for (let i = 1; i < array.length; i++) {
        if (array[i] < minValue) {
          minValue = array[i];
          minIndex = i;
        }
      }
      return minIndex;
    }

    const apiFormData = {
      pincode: productpin || '',
      productid: productId || '',
      variantid: productvarient || '',
      quantity: parseInt(productqunatity, 10) || 0,
    };
    console.log(apiFormData)
    let data = await shippingprice(apiFormData);
    console.log("fetch", data.data);
    setDataofship(data.data)

    const warehouseindex = data.data.vendorWareHouse.findIndex((vendor) => vendor.warehouseid == selectedware);
    console.log(warehouseindex);

    // const sumArray = addArrays(data.data.shipCost, data.data.pricesresult);
    // console.log(sumArray);
    // const minIndex = findIndexOfMinValue(sumArray);


    setModaldata({
      ...modaldata,
      productid: productId,
      porid: porId,
      variantid: productvarient,
      quantity: productqunatity,
      pincode: productpin,
      productname: productname,
      productprice: parseFloat(productprice1),
      admintotalprice: parseFloat(productprice1) + parseFloat(admshipprice),
      adminshippingprice: parseFloat(admshipprice),
      vendorprice: data.data.pricesresult[warehouseindex] * productqunatity,
      vendorshippingprice: data.data.shipCost[warehouseindex],
      vendortotalprice: data.data.pricesresult[warehouseindex] * productqunatity + data.data.shipCost[warehouseindex],
      vendors: data.data,
      vendorsdetail: vendorsdetail,
      selectedware: selectedware,
      selectedvendor: selectedvendor
    })

    // openmodel();


  };

  useEffect(() => {
    console.log(modaldata);
    if (modaldata.productid && modaldata.porid && modaldata.variantid && modaldata.quantity && modaldata.pincode && modaldata.productname && modaldata.productprice && modaldata.admintotalprice && modaldata.adminshippingprice && modaldata.vendorprice && modaldata.vendorshippingprice && modaldata.vendortotalprice && modaldata.vendors, modaldata.selectedware, modaldata.selectedvendor) {
      setShowModal(true);
    }
    // setShowModal(true);
  }, [modaldata]);


  const handleCloseModal = () => {
    setModaldata({
      productid: "",
      porid: "",
      variantid: "",
      quantity: "",
      pincode: "",
      productname: "",
      productprice: "",
      admintotalprice: "",
      adminshippingprice: "",
      vendorprice: "",
      vendorshippingprice: "",
      vendortotalprice: "",
      vendors: [],
      vendorsdetail: []
    });
    setShowModal(false);
  };

  const handleVendorSelection = async () => {
    console.log("Vendor selected:", modaldata.selectedvendor);
    console.log("Warehouse selected:", modaldata.selectedware);
    console.log("porid:", modaldata.porid);
    console.log("productid:", modaldata.productid);
    const sv = modaldata.selectedvendor;
    const sw = modaldata.selectedware;
    const porid = modaldata.porid;
    const productid = modaldata.productid;
    // setSelectedVendorId(vendorId);

    const data = await handleVendorSelect(sv, sw, porid, productid);
    setShowModal(false);
    window.location.reload();
  };

  const approve = async (porid, productid) => {
    let dat = await confirm_vendor(porid, productid);
    console.log(dat);
    if (dat == "confirmed") {
      alert(dat);

    } else {
      alert("Something went wrong");
    }
  };




  return (
    <>
      {porData &&
        porData.map((innerArray, index) => (
          <React.Fragment key={index}>
            {innerArray.products.map((item, itemIndex) => {
              srno++;
              return (
                <tr key={itemIndex}>
                  <th>{srno}</th>
                  <th>{innerArray.OrdID}</th>
                  <th>{innerArray.username}</th>
                  <th>{item.variationName}</th>
                  <th>
                    {item.vendors.find(vendor => vendor.vendorid === item.selectedvendor)?.vendorName}
                  </th>
                  <th>
                    {item.warehouse[0].name}
                  </th>
                  <th>
                    {item.vendors.find(vendor => vendor.vendorid === item.selectedvendor)?.vendorEmail}
                  </th>
                  <th>
                    {item.warehouse[0].vendorphoneno}
                    {/* {item.vendors.find(vendor => vendor.vendorid === item.selectedvendor)?.phoneNo} */}
                  </th>
                  <th>{item.quantity}</th>
                  <th>{item.totalPrice}</th>
                  <th>{item.vendorStatus ? (
                    <>
                      <button className="btn btn-success mt-2">Approved</button>
                    </>
                  ) : (
                    <>
                      <button
                        className="btn btn-danger mt-2"
                        onClick={(e) => {
                          approve(innerArray._id, item.productid);
                        }}
                      >
                        PENDING
                      </button>
                    </>
                  )}</th>
                  <th>
                    <Button onClick={() => handleOpenModal(innerArray._id, item.productid, item.variationName, item.totalPrice, item.quantity, item.adminshippingprice, item.varientid, item.pincode, item.vendors, item.selectedware, item.selectedvendor)}>Select Vendor</Button>
                  </th>
                </tr>
              )
            })}
          </React.Fragment>
        ))}
      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Select Vendor</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p><strong>Product Name:</strong> {modaldata.productname}</p>
          <p><strong>Product Quantity:</strong> {modaldata.quantity}</p>
          <p><strong>Admin price:</strong> {modaldata.productprice}</p>
          <p><strong>Admin shipping price:</strong> {modaldata.adminshippingprice}</p>
          <p><strong>Admin totalprice:</strong> {modaldata.admintotalprice}</p>
          <p><strong>Vendor price:</strong> {modaldata.vendorprice}</p>
          <p><strong>Vendor shipping price:</strong> {modaldata.vendorshippingprice}</p>
          <p><strong>Vendor total price:</strong> {modaldata.vendortotalprice}</p>






          {modaldata.porid && modaldata.productid && (
            <div>
              <label>Select Vendor:</label>
              <select
                value={selectedVendorId}
                onChange={handleVendorChange}
              >
                {Array.from(
                  new Map(modaldata.vendorsdetail.map(vendor => [vendor.vendorid, vendor])).values()
                ).map((vendor, vendorIndex) => (
                  <option key={vendorIndex} value={vendor.vendorid}>
                    {vendor.vendorName}
                  </option>
                ))}
              </select>
            </div>
          )}
          <div>
            <label>Select Warehouse:</label>
            <select
              value={warehouseid}
              onChange={handlewarehousechange}
            >
              {warehouse.map((warehouse, index) => (
                <option key={index} value={warehouse._id}>
                  {warehouse.name}
                </option>
              ))}
            </select>
          </div>

        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
          <Button variant="primary" onClick={handleVendorSelection}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
