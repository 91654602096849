import React, { useState, useEffect } from "react";
import Footer from "../footer/Footer";
import Pagination from "../categories/categories/Pagination";
import { StatusPosts } from "./StatusPosts";
import { porStatus } from "../../services/api";

function Status() {
  const [posts, setPosts] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage] = useState(25);
  const [searchQuery, setSearchQuery] = useState("");

  // total no of pages
  const Totalpages = Math.ceil(posts.length / postsPerPage);
  const pages = [...Array(Totalpages + 1).keys()]?.slice(1);

  // Get current posts
  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = posts.slice(indexOfFirstPost, indexOfLastPost);

  // Filter posts based on search query
  const filteredPosts = currentPosts.filter(post =>
    post.OrdID.toLowerCase().includes(searchQuery.toLowerCase())
  );

  // Change page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  useEffect(() => {
    async function fetchData() {
      let dat = await porStatus();
      if (dat) {
        console.log(dat);
        setPosts(dat);
      }
    }
    fetchData();
  }, []);

  const clearSearch = () => {
    setSearchQuery("");
  };

  return (
    <>
      <div className="content-page">
        <div className="content">
          <div className="container-fluid">
            <div className="page-title-box">
              <div className="row align-items-center">
                <div className="col-sm-6">
                  <h4 className="page-title">POR Status</h4>
                </div>
                <div className="col-sm-6">
                  <ol className="breadcrumb float-right"></ol>
                </div>
              </div>
            </div>

            <div className="row d-md-flex justify-content-md-end ">
              <div className="col-sm-6 d-md-flex justify-content-md-end"></div>
            </div>

            <div className="row">
              <div className="col-xl-12">
                <div className="card m-b-30">
                  <div className="card-body">
                    <h4 className="mt-0 header-title mb-4">POR Status</h4>

                    {/* Search Input Field */}
                    <div className="search-container mb-3 position-relative">
                      <input
                        type="text"
                        placeholder="Search by Order ID"
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                        className="form-control"
                      />
                      {searchQuery && (
                        <button
                          type="button"
                          className="btn btn-clear"
                          onClick={clearSearch}
                          aria-label="Clear Search"
                        >
                          <i className="fas fa-times"></i> {/* Font Awesome cross icon */}
                        </button>
                      )}
                    </div>

                    <div className="table-responsive">
                      <table className="table table-hover">
                        <thead>
                          <tr>
                            <th scope="col">Sr no</th>
                            <th>Order Id</th>
                            <th>Customer</th>
                            <th>Product Name</th>
                            <th scope="col">Vendor Name</th>
                            <th scope="col">Warehouse Name</th>
                            <th scope="col">Email</th>
                            <th scope="col">Phone Number</th>
                            <th scope="col">Quantity</th>
                            <th> Total Price</th>
                            <th scope="col">Request Status</th>
                            <th scope="col">Select Vendor</th>
                          </tr>
                        </thead>
                        <tbody>
                          <StatusPosts porData={filteredPosts} />
                        </tbody>
                      </table>
                    </div>
                    <Pagination
                      postsPerPage={postsPerPage}
                      totalPosts={posts.length}
                      paginate={paginate}
                      currentPage={currentPage}
                      pageNumbers={pages}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
}

export default Status;
